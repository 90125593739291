<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Vendedor..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedDoctores || !selectedDoctores.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="vendedores" v-model:selection="selectedVendedores" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} vendedores">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Vendedores</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<!-- <Column field="id_doctor" header="Id" :sortable="true"></Column> -->
					<Column field="nombre" header="Vendedor" :sortable="true"></Column>
					<Column field="celular" header="Celular" :sortable="true"></Column>
					<Column field="email" header="Correo electrónico" :sortable="true"></Column>
                    <!-- <Column field="tipo" header="Tipo" :sortable="true"></Column> -->
                    <!--<Column field="especialidad" header="Especialidad" :sortable="true"></Column>-->
                    <!--<Column field="apoyo" header="Apoyo" :sortable="true"></Column>-->
                    <!--<Column field="fecha_alta" header="Alta" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_alta)}}
                        </template>
					</Column>-->
                    <!--<Column field="fecha_baja" header="Baja" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_baja)}}
                        </template>
					</Column>-->
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editVendedor(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteVendedor(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="vendedorDialog" :style="{width: '550px'}" header="Vendedor" :modal="true" class="p-fluid">
					<div class="p-field">
						<label for="nombre">Nombre*</label>
						<InputText id="nombre" v-model.trim="vendedor.nombre" required="true" autofocus :class="{'p-invalid': submitted && !vendedor.nombre}" />
						<small class="p-invalid" v-if="submitted && !vendedor.nombre">El nombre del vendedor es requerido</small>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="celular">Celular</label>
							<InputText id="celular" v-model="vendedor.celular" />
						</div>
						<div class="p-field p-col">
							<label for="email">Correo electrónico</label>
							<InputText id="email" v-model.trim="vendedor.email" />
						</div>
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo" :binary="true"  v-model="vendedor.activo" />
                        <label for="activo">Activo</label>
                    </div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveVendedor" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteVendedorDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="vendedor">¿Desea eliminar el vendedor <b>{{vendedor.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteVendedorDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteVendedor" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteVendedoresDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="doctor">¿Desea eliminar los vendedores seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteVendedoresDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedVendedores" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import VendedorService from '../service/VendedorService';
import UsuarioService from '../service/UsuarioService';
import moment from 'moment';
import XLSX from 'xlsx'
import Cookies from "js-cookie"
window.Cookies = Cookies
export default {
	data() {
		return {
			vendedores: null,
			vendedorDialog: false,
			deleteVendedorDialog: false,
			deleteVendedoresDialog: false,
			vendedor: {},
			selectedVendedores: null,
			filters: {},
			submitted: false,	

			vendedorPost:[],
			vendedorid:null,
			correo:null,
			celular:null,
			VendedoresExpor:null,
			useconver:null,
			userdata:null,
			usuarios:null,
		}
	},
	vendedorService: null,

	created() {
		this.vendedorService = new VendedorService();
		this.usuarioService = new UsuarioService();
	},
	mounted() {
		this.vendedorService.getVendedor().then(data => { this.vendedores = data});
		this.usuarioService.getUsuarios().then(data => { this.usuarios = data});
	},
	methods: {
		openNew() {
			this.vendedor = {};
			this.submitted = false;
			this.vendedorDialog = true;
			this.vendedor.activo=true;
		},
		hideDialog() {
			this.vendedorDialog = false;
			this.submitted = false;
		},
		saveVendedor() {
			this.submitted = true;
			this.useconver = Cookies.get("user");

			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}
           
			if (this.vendedor.nombre.trim()) {
				
				if (this.vendedor.id_vendedor>0) {

					if( typeof this.vendedor.email != 'undefined'){
						this.correo=this.vendedor.email;
					}

					if( typeof this.vendedor.celular != 'undefined'){
						this.celular=this.vendedor.celular;
					}
					
					this.vendedorPost.push({
						"id_vendedor":this.vendedor.id_vendedor,
						"nombre":this.vendedor.nombre,
						"celular":this.celular,
						"email":this.correo,
						"activo":this.vendedor.activo,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});
					// console.log(this.doctorPost); 
					this.vendedorService.updateVendedor(this.vendedorPost[0]).then(document.location.reload());
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Vendedor actualizado', life: 3000});
					
					this.vendedorPost=[];
					this.vendedorid=null;
					this.correo=null;
					this.celular=null;
				}
				else {
					 
					if( typeof this.vendedor.email != 'undefined'){
						this.correo=this.vendedor.email;
					}

					if( typeof this.vendedor.celular != 'undefined'){
						this.celular=this.vendedor.celular;
					}

					this.vendedorPost.push({
						"nombre":this.vendedor.nombre,
						"celular":this.celular,
						"email":this.correo,
						"activo":this.vendedor.activo,
						"id_usuario_reg":this.userdata[0].id_usuario,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});
					 
					 
					this.vendedorService.createVendedor(this.vendedorPost[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Vendedor creado', life: 3000});
					
					 
					this.vendedorPost=[];
					this.vendedorid=null;
					this.correo=null;
					this.celular=null;
				 	document.location.reload();
				}

				this.vendedorDialog = false;
				 
			}
		},
		editVendedor(vendedor) {
			this.vendedor = {...vendedor};
			this.vendedorDialog = true;
		},

		confirmDeleteVendedor(vendedor) {
			this.vendedor = vendedor;
			this.deleteVendedorDialog = true;
		},

		deleteVendedor() {
			this.useconver = Cookies.get("user");
			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}

			this.deleteVendedorDialog = false;
					
					if( typeof this.vendedor.email != 'undefined'){
						this.correo=this.vendedor.email;
					}

					if( typeof this.vendedor.celular != 'undefined'){
						this.celular=this.vendedor.celular;
					}

					this.vendedorPost.push({
						"id_vendedor":this.vendedor.id_vendedor,
						"nombre":this.vendedor.nombre,
						"celular":this.celular,
						"email":this.correo, 
						"activo":0,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});

					this.vendedorService.updateVendedor(this.vendedorPost[0]).then(document.location.reload());
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Vendedor eliminado', life: 3000});				
		},

		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.vendedores.length; i++) {
				if (this.vendedores[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.vendedores.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteVendedoresDialog = true;
		},
		deleteSelectedDoctores() {
			this.vendedores = this.vendedores.filter(val => !this.selectedVendedores.includes(val));
			this.deleteVendedoresDialog = false;
			this.selectedVendedores = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Vendedores eliminados', life: 3000});
			
		},
		turno(value){
			if(value ==1){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		computedDatesFormat(value) {
			if(value !=null){
                return moment(value).format('DD/MM/YYYY')
			}
		},
		download : function() {
			this.vendedorService.getVendedor().then(data => {
				this.VendedoresExpor = data;
				
				for(var i in  this.VendedoresExpor)
				{ 
					for(var us in this.usuarios){
						if(data[i].id_usuario_reg == this.usuarios[us].id_usuario)
						{
							this.VendedoresExpor[i].id_usuario_reg=this.usuarios[us].nombre+" "+this.usuarios[us].apellido_paterno+" "+this.usuarios[us].apellido_materno;
						}
					}

					for(var us1 in this.usuarios){
						if(data[i].id_usuario_mod == this.usuarios[us1].id_usuario)
						{
							this.VendedoresExpor[i].id_usuario_mod=this.usuarios[us1].nombre+" "+this.usuarios[us1].apellido_paterno+" "+this.usuarios[us1].apellido_materno;
						}
					}
				}

				if(this.VendedoresExpor != null){
				const data = XLSX.utils.json_to_sheet(this.VendedoresExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoVendedores.xlsx')
				}
			});
	
		},
		computedDatesFormatSave(value) {
                return moment(value).format('YYYY/MM/DD')
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.doctor-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .doctor-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.doctor-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
