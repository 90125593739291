import axios from 'axios';

export default class VendedorService {

	getVendedor() {
		const url = `/api/vendedor`;
		return axios.get(url).then(res=>res.data.data);
	}

	createVendedor(vendedor) {
		const url = `/api/vendedor`;		
		return axios.post(url,vendedor).then(res=>res.data.data);
	}
	
	updateVendedor(vendedor) {
		const url = `/api/vendedor/${vendedor.id_vendedor}`;
		return axios.put(url,vendedor).then(res=>res.data.data);
	}
	
	deleteVendedor(vendedor) {
		const url = `/api/vendedor/${vendedor.id_vendedor}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}